<template>
  <div id="app">
    <div
      class="p-1"
      v-if="error"
    >
      <h1>Não foi possível carregar a loja</h1>
      <h2>{{error}}</h2>
    </div>
    <div v-else-if="!ready">
    </div>
    <Pagina v-else />
  </div>
</template>

<script>
import Pagina from './components/Pagina.vue'

export default {
  created () {
    this.api.getDatabase(location.hostname)
      .then(database => {
        this.deliveryStore.setDatabase(database)
        this.ready = true
      }).catch(error => {
        this.error = error
      })
  },
  data () {
    return {
      error: '',
      ready: false
    }
  },
  components: { Pagina }
}
</script>

<style scoped>
</style>
