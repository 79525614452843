<template>
  <span class="fixed top right mt-4 p-1 cursor-pointer">
    <span @click="deliveryStore.toggleCarrinho">
      <span
        class="absolute pt-2 pr-1 top right"
        v-show="deliveryStore.produtos.length"
      >
        <span class="t-bolder bg-theme-color badge">
          <span class="">{{deliveryStore.produtos.length > 99 ? '+99': deliveryStore.produtos.length}}</span>
        </span>
      </span>
      <ShoppingCart
        class="p-1 rounded-full bg-theme-color-contrast"
        style="height:48px;width:48px"
      />
    </span>
  </span>
</template>

<script>
import ShoppingCart from './svgs/ShoppingCart'
export default {
  components: { ShoppingCart }
}
</script>

<style scoped>
</style>
