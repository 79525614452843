import Vue from 'vue'

import computed from './computed'
import methods from './methods'

function storeFactory () {
  const store = {
    data () {
      return {
        isCarrinhoVisible: false,
        database: {
          cardapios: [],
          complementos: [],
          lojas: [{}],
          formasdepagamento: [],
          horarios: [],
          produtos: [],
          secoes: [],
          themes: [{}]
        },
        locationError: '',
        produto: {},
        produtos: [],
        ready: false
      }
    },
    computed,
    methods
  }
  return new Vue(store)
}

export default { create: storeFactory }
