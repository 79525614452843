<template>
  <div class="Pagina bg-color-background-alt">
    <Cabecalho />
    <Cardapio />
    <Rodape />
    <JanelaProduto />
    <BotaoCarrinho />
    <Carrinho />
  </div>
</template>

<script>
/* eslint-disable */
import BotaoCarrinho from './BotaoCarrinho.vue'
import Cabecalho from './Cabecalho.vue'
import Cardapio from './Cardapio.vue'
import Carrinho from './Carrinho.vue'
import JanelaProduto from './JanelaProduto'
import Rodape from './Rodape.vue'

export default {
  components: { BotaoCarrinho, Cabecalho, Cardapio, Carrinho, JanelaProduto, Rodape }
}
</script>

<style scoped>
</style>
