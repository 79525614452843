<template>
  <div :class="{ 'active': deliveryStore.isCarrinhoVisible }">
    <div
      class="backdrop bg-theme-color"
      v-show="deliveryStore.isCarrinhoVisible"
      @click="deliveryStore.toggleCarrinho"
    ></div>

    <div
      class="offcanvas-panel offcanvas-panel-all offcanvas-panel-right
        bg-color-background bs p-3"
      :class="{ 'active': deliveryStore.isCarrinhoVisible }"
      style="width: 600px;max-width: 95%;"
    >
      <div class="flex flex-middle flex-center mb-3">
        <div class="h3 m-0 flex-item-grow-1">
          Ítens do Pedido
        </div>

        <div
          class="cursor-pointer m-1"
          @click="deliveryStore.toggleCarrinho"
        >
          <Close />
        </div>
      </div>
      <div
        class="t-center"
        v-if="!deliveryStore.produtos.length"
      >
        <ShoppingCartX />
        <h5>Você ainda não adicionou produtos ao seu pedido</h5>
      </div>
      <div
        class="flex-item-grow-1"
        v-else
      >
        <div
          v-for="(produto, index) in deliveryStore.produtos"
          :key="index"
        >
          <div class="flex flex-top bb-thin mb-1 pb-1 ">
            <div class="flex-item-grow-1 pr-2">
              <h5 class="m-0">{{produto.nome}}</h5>
              <a
                @click="deliveryStore.removerProduto(index)"
                class="t-color-text t-sm cursor-pointer"
              >Remover Produto</a>
            </div>
            <div class="t-right">
              <h5 class="m-0">{{deliveryStore.obtemValorDoProduto(produto) | currency}}</h5>
              <QtdeProduto :produto="produto" />
            </div>
          </div>
        </div>
        <div class="t-right">
          <h5>Total {{deliveryStore.totalDosItens | currency}}</h5>
        </div>
        <div class="t-center">
          <button class="btn">Finalizar Pedido</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Close from './svgs/Close'
import QtdeProduto from './QtdeProduto'

import ShoppingCartX from './svgs/ShoppingCartX'

export default {
  components: { Close, ShoppingCartX, QtdeProduto }
}
</script>
