import applyThemeCssVariables from '../helpers/applyThemeCssVariables'
import defaultDatabase from '../helpers/defaultDatabase'

export default function setDatabase (database) {
  this.database = { ...defaultDatabase, ...database }
  const titleElement = document.querySelector('title')
  titleElement.text = this.loja.nome
  applyThemeCssVariables(this.theme)
  this.ready = true

}
