<template>
  <div
    class="b-thin rounded-sm cursor-pointer flex flex-row"
    style="height: 160px;"
    @click="deliveryStore.mostraProduto(produto)"
  >
    <div
      class="flex flex-column p-2"
      style="flex-grow: 1;flex-shrink: 1;word-break: break-word;"
    >
      <div class="t-bold h5 m-0">{{produto.nome}}</div>
      <div class="line-clamp-2">
        {{produto.descricao }}
      </div>
      <div class="flex-item-grow-1 py-1"></div>
      <div>
        <span class="m-0 t-bold">{{produto.preco | currency}}</span>
      </div>
    </div>
    <div
      v-if="produto.img"
      class="bg-theme-color"
      style="flex-shrink: 0;width: 160px;height:160px"
    >
      <img
        class="img-cover"
        :src="produto.img"
        :alt="`foto de ${produto.nome}`"
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    produto: Object
  }
}
</script>

<style scoped>
</style>
