<template>
  <div class="CardapioSecaoNav bg-color-background-alt pt-4">
    <div class="p-4">
      <h4 class="t-bolder t-theme-color">{{ secao.nome }}</h4>
      <div class="hide-below-sm grid-sm-2-cols grid-lg-3-cols grid-gap">
        <CardapioProdutoHorizontal
          v-for="(produto, index) in secao.produtos"
          :produto="produto"
          :key="index"
        />
      </div>
      <div class="hide-sm-up">
        <CardapioProdutoVertical
          v-for="(produto, index) in secao.produtos"
          :produto="produto"
          :key="index"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CardapioProdutoHorizontal from "./CardapioProdutoHorizontal"
import CardapioProdutoVertical from "./CardapioProdutoVertical"

export default {
  props: {
    index: Number,
    secao: Object
  },
  components: { CardapioProdutoHorizontal, CardapioProdutoVertical }
}
</script>

<style scoped>
</style>
