export default function podeAdicionarProduto (produto) {
  const complementos = produto.complementos || []
  for (const complemento of complementos) {
    const { nome, obrigatorio, minItens, maxItens, maxPorItem, itens } = complemento
    let qtdeItens = 0
    for (const item of itens) {
      const { qtde } = item
      if (maxPorItem && qtde > maxPorItem) {
        return `${nome}: máximo ${maxPorItem} unidades`
      }
      qtdeItens += qtde
    }
    if (maxItens && qtdeItens > maxItens) {
      return `${nome}: escolha no máximo ${maxItens} ítens`
    }
    if (obrigatorio && qtdeItens === 0) {
      return `${nome}: escolha obrigatória`
    }
    if (minItens && qtdeItens < minItens) {
      return `${nome}: escolha no mínimo ${minItens} ítens`
    }
  }
}
