import adicionarProduto from './adicionarProduto'
import atualizaQuantidadeItem from './atualizaQuantidadeItem'
import escondeProduto from './escondeProduto'
import mostraProduto from './mostraProduto'
import obtemValorDoProduto from './obtemValorDoProduto'
import removerProduto from './removerProduto'
import setDatabase from './setDatabase'
import toggleCarrinho from './toggleCarrinho'

export default {
  adicionarProduto,
  atualizaQuantidadeItem,
  escondeProduto,
  mostraProduto,
  obtemValorDoProduto,
  removerProduto,
  setDatabase,
  toggleCarrinho
}