<template>
  <div class="sticky top bg-theme-color overflow-x bs-2">
    <div
      ref="tabs"
      class="t-long-read p-1 flex flex-middle overflow-x no-selection"
    >
      <div
        v-for="(secao, index) in deliveryStore.cardapio.secoes"
        :key="index"
        class="px-1"
      >
        <div
          class="h5 m-0 cursor-pointer px-1 t-nowrap b-theme-color-contrast"
          :class="{'bb-thick': index === tabIndex}"
          @click="onTabClick(index)"
        >
          {{secao.nome}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted () {
    this.secoesNodeList = document.querySelectorAll('.CardapioSecaoNav')
    document.addEventListener('scroll', this.onScroll, false)
    this.updateFirstVisible()
    setTimeout(this.updateFirstVisible, 250)
  },
  beforeDestroy () {
    document.removeEventListener('scroll', this.onScroll)
    document.removeEventListener('ready', this.updateFirstVisible, false)
  },
  data () {
    return {
      debounce: 50,
      firstVisibleIndex: null,
      isScrolling: false,
      secoesNodeList: null,
      tabIndex: null
    }
  },
  methods: {
    onScroll () {
      this.isScrolling = true
      this.updateFirstVisible()
      clearTimeout(this.scrollTimeout)
      this.scrollTimeout = setTimeout(() => {
        this.updateFirstVisible()
        this.isScrolling = false
        if (this.viaClick) {
          this.viaClick = false
        } else {
          clearTimeout(this.tabScrollTimeout)
          this.tabScrollTimeout = setTimeout(() => {
            const tab = this.$refs.tabs.children[this.firstVisibleIndex]
            if (tab && !isScrolledIntoView(tab, true)) {
              tab.scrollIntoView({ block: "start", behavior: "smooth" })
            }
          }, this.debounce)
        }
      }, this.debounce)
    },
    onTabClick (index) {
      this.viaClick = true
      const secao = this.secoesNodeList[index]
      secao.scrollIntoView({ block: "start", behavior: "smooth" })
    },
    updateFirstVisible () {
      this.firstVisibleIndex = getFirstVisibleIndex(this.secoesNodeList)
      if (this.firstVisibleIndex !== -1) {
        this.tabIndex = this.firstVisibleIndex
      }
    }
  }
}

function getFirstVisibleIndex (nodeList) {
  for (let index = 0; index < nodeList.length; ++index) {
    if (isScrolledIntoView(nodeList[index], false, 220)) {
      return index
    }
  }
  return -1
}

function isScrolledIntoView (el, mustBeFullyVisible, offset) {
  offset = offset || 0
  const { top, right, bottom, left } = el.getBoundingClientRect()
  const { innerHeight, innerWidth } = window
  const result = mustBeFullyVisible
    ?
    (top - offset) >= offset && bottom <= innerHeight &&
    left >= offset && right <= (innerWidth - offset)
    :
    top < (innerHeight - offset) && bottom >= offset &&
    left <= (innerWidth - offset) && right >= offset
  return result
}
</script>
