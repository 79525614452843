<template>
  <div>
    <CardapioSecoesNav />
    <CardapioSecao
      v-for="(secao, index) in deliveryStore.cardapio.secoes"
      :key="index"
      :index="index"
      :secao="secao"
    />
  </div>
</template>

<script>
import CardapioSecao from './CardapioSecao'
import CardapioSecoesNav from './CardapioSecoesNav'

export default {
  components: { CardapioSecao, CardapioSecoesNav }
}
</script>

<style scoped>
</style>
