<template>
  <div
    class="mb-1 bb-thin p-1 cursor-pointer"
    @click="deliveryStore.mostraProduto(produto)"
  >
    <div class="flex flex-middle">
      <div class="h4 m-0 flex-item-grow-1">{{produto.nome}}</div>
      <div class="pl-1 t-right t-bold">{{produto.preco | currency}} </div>
    </div>
    <img
      v-if="produto.img"
      class="img-cover"
      style="width:100px;margin:0 auto"
      :src="produto.img"
      :alt="`foto de ${produto.nome}`"
    >
    <div class="line-clamp-3">
      {{produto.descricao }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    produto: Object
  }
}
</script>

<style scoped>
</style>
