export default function applyThemeCssVariables (theme) {
  const color = theme.color || 'white'
  const colorContrast = getContrast(color, theme.colorContrast)
  const rootStyle = document.documentElement.style
  rootStyle.setProperty('--theme-color', color)
  rootStyle.setProperty('--theme-color-contrast', colorContrast)
}

function getContrast (color, colorContrast) {
  return colorContrast ? colorContrast : getContrastColor(color)
}

function getContrastColor (hexcolor) {
  if (!hexcolor)
    return null
  if (hexcolor.length == 4)
    hexcolor += hexcolor.substr(1, 3)
  var result = (luma(hexcolor) >= 165) ? '#0d0d0d' : '#FFFFFF'
  return result
}

function luma (hexcolor) {
  if (!hexcolor) {
    return null
  }
  if (hexcolor.substr(0, 1) == '#') {
    hexcolor = hexcolor.substr(1)
  }
  var r = parseInt(hexcolor.substr(0, 2), 16)
  var g = parseInt(hexcolor.substr(2, 2), 16)
  var b = parseInt(hexcolor.substr(4, 2), 16)
  return (0.2126 * r) + (0.7152 * g) + (0.0722 * b) // SMPTE C, Rec. 709 weightings
}
