export default function buildComplemento (idComplemento, dados) {
  const { complementos, produtos } = dados
  const complemento = complementos.find(complemento => complemento.id === idComplemento)
  if (complemento) {
    const { componente, checked, itens: idProdutos, ...rest } = complemento
    const itens = idProdutos
      .map(({ idProduto }) => {
        const produto = produtos.find(produto => produto.id === idProduto)
        if (produto && !produto.status !== 'pausada') {
          return { ...produto }
        }
      })
      .filter(produto => produto)
      .map(produto => {
        const qtde = componente === 'checks' && checked ? 1 : 0
        const item = { ...produto, qtde }
        return item
      })
    return { ...rest, componente, checked, itens }
  }
}