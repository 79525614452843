export default function oncurrentItemActionClick (complemento, currentItem, action) {
  const { componente, itens, maxItens, maxPorItem } = complemento
  action = componente === 'add' ? action : componente === 'radio' ? 'select' : 'toggle'
  const total = itens.map(item => item.qtde).reduce((accumulator, currentValue) => accumulator + currentValue)
  let novaQtde
  switch (action) {
    case 'toggle':
      novaQtde = currentItem.qtde ? 0 : 1
      break
    case 'select':
      itens.forEach(item => {
        item.qtde = item === currentItem ? 1 : 0
      })
      break
    case 'inc':
      novaQtde = Math.max(maxPorItem, currentItem.qtde + 1)
      break
    case 'dec':
      novaQtde = Math.max(0, currentItem.qtde - 1)
      break
  }
  const canApply = maxItens ? total + novaQtde - currentItem.qtde <= maxItens : true
  if (action !== 'select' && canApply) {
    currentItem.qtde = novaQtde
  }
  return canApply
}
