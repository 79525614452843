import buildProduto from './buildProduto'

export default function buildCardapio (dados) {
  const { cardapios } = dados
  const { secoes: secoesCardapio } = cardapios[0]
  const secoes = secoesCardapio
    .map(secao => {
      const { itens } = secao
      if (secao.status !== 'pausada') {
        const produtos = itens
          .map(({ idProduto }) => buildProduto(idProduto, dados))
          .filter(produto => produto)
        return { ...secao, produtos }
      }
    })
    .filter(secao => secao)
  return { secoes: secoes }
}
