import buildComplemento from './buildComplemento'

export default function buildProduto (idProduto, dados) {
  const { produtos } = dados
  const produto = produtos.find(produto => produto.id === idProduto)
  if (produto && produto.status !== 'pausada') {
    const { complementos: idComplementos, ...rest } = produto
    const complementos = (idComplementos || [])
      .map(({ idComplemento }) => buildComplemento(idComplemento, dados))
      .filter(complemento => complemento)
    return { ...rest, qtde: 1, complementos }
  }
}
