<template>
  <div class="modal-wrapper modalProdutoActive flex-sm flex-center flex-middle">
    <div
      class="backdrop backdrop-shaded"
      @click="deliveryStore.escondeProduto"
    ></div>
    <div class="modal-panel ProdutoDialog modalProdutoActive bg-color-background-alt flex flex-column">
      <div class="bg-theme-color t-long-read flex flex-middle">
        <div class="px-1 t-theme-color-contrast h4 m-0 flex-item-grow-1">{{deliveryStore.produto.nome}}</div>
        <div @click="deliveryStore.escondeProduto">
          <Close class="mx-1 t-theme-color-contrast cursor-pointer" />
        </div>
      </div>
      <div class="flex-item-grow-1 flex-sm overflow-y">
        <div
          class="sticky top-0 p-4 flex flex-center"
          v-if="deliveryStore.produto.img"
        >
          <img
            class="square-lg"
            :src="deliveryStore.produto.img"
          />
        </div>
        <div
          class="px-1 flex-item-grow-1 bg-color-background-alt relative"
          style="z-index:9999;"
        >
          <div class="t-long-read">
            <h5 class="m-0">{{deliveryStore.produto.descricao}}</h5>
          </div>
          <Complementos :complementos="deliveryStore.produto.complementos" />
        </div>
      </div>
      <div class="flex flex-middle flex-end p-1 bs-1">
        <div
          v-if="deliveryStore.podeAdicionarProduto"
          class="t-bold t-color-danger"
        >
          {{deliveryStore.podeAdicionarProduto}}
        </div>
        <div
          v-else
          class="flex flex-middle flex-end flex-wrap"
        >
          <QtdeProduto
            class="m-1"
            :produto="deliveryStore.produto"
          />
          <button
            class="m-1 btn"
            style="min-width: 200px"
            @click="deliveryStore.adicionarProduto(deliveryStore.produto)"
          >
            Adicionar {{deliveryStore.obtemValorDoProduto(deliveryStore.produto) | currency}}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Complementos from './Complementos'
import Close from './svgs/Close'
import QtdeProduto from './QtdeProduto'

export default {
  components: { Close, Complementos, QtdeProduto }
}
</script>

<style scoped>
.ProdutoDialog {
  width: 800px;
  max-width: 99%;
  max-height: 95%;
}
</style>
