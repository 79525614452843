<template>
  <div class="flex flex-inline flex-middle bg-color-backgrounds b-thin b-color-text rounded-pill">
    <button
      class="btn btn-sm t-bolder bg-color-background-alt t-color-text b-0"
      @click.stop="decrementaQuantidadeProduto()"
    >
      &minus;
    </button>
    <div
      class="flex flex-middle flex-center"
      style="width: 50px;"
    >
      <div class="p-0 m-0">{{produto.qtde}}</div>
    </div>
    <button
      class="btn btn-sm t-bolder bg-color-background-alt t-color-text b-0"
      @click.stop="incrementaQuantidadeProduto()"
    >
      &plus;
    </button>
  </div>
</template>
<script>

export default {
  props: { produto: Object },
  methods: {
    decrementaQuantidadeProduto () {
      if (this.produto.qtde > 1) {
        --this.produto.qtde
      }
    },
    incrementaQuantidadeProduto () { ++this.produto.qtde }
  }
}
</script>
