<template>
  <div class=" bg-color-background-alt pb-1">
    <div class="t-long-read bg-color-background-alt sticky top-0 pb-1">
      <div class="t-bolder t-theme-color flex-item-grow-1">{{complemento.nome}}</div>
      <div class="t-sm">({{detalhes}})</div>
    </div>
    <ComplementoItens :complemento="complemento" />
  </div>
</template>

<script>
import ComplementoItens from './ComplementoItens'

export default {
  props: {
    complemento: Object
  },
  components: { ComplementoItens },
  computed: {
    componente () { return this.complemento.componente },
    detalhes () {
      const { obrigatorio, maxItens } = this.complemento
      const detalhes = []
      detalhes.push(obrigatorio ? 'Obrigatório' : 'Opcional')
      if (maxItens) {
        detalhes.push(`Máximo: ${maxItens}`)
      }
      return detalhes.join(', ')
    }
  }
}
</script>

<style scoped>
</style>
