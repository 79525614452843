import buildCardapio from '../helpers/buildCardapio'
import calculaTotalDosItens from '../helpers/calculaTotalDosItens'
import podeAdicionarProduto from '../helpers/podeAdicionarProduto'

export default {
  cardapio () { return buildCardapio(this.database) },
  loja () { return this.database.lojas[0] || {} },
  podeAdicionarProduto () { return podeAdicionarProduto(this.produto) },
  theme () { return this.database.themes[0] || {} },
  totalDosItens () { return calculaTotalDosItens(this.produtos, this.dados) }
}

