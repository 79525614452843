<template>
  <div>
    <div
      class="flex flex-middle py-1 no-selection bt-thin"
      :class="{'cursor-pointer': componente!=='add'}"
      v-for="(item, index) in complemento.itens"
      :key="index"
      @click="atualizaQuantidadeItem(item)"
    >
      <div class="t-color-default pr-1">
        <div v-if="componente==='checks'">
          <SquareEmpty v-if="item.qtde === 0" />
          <SquareCheck v-else />
        </div>
        <div v-else-if="componente==='radio'">
          <CircleEmpty v-if="item.qtde === 0" />
          <CircleDot v-else />
        </div>
        <div v-else>
          <div class="flex flex-middle bg-color-background-alt b-thin b-color-text rounded-pill">
            <button
              class="btn btn-sm t-bolder bg-color-background-alt t-color-text b-0"
              @click.stop="atualizaQuantidadeItem(item, 'dec')"
            >
              &minus;
            </button>
            <div
              class="flex flex-middle flex-center"
              style="width: 50px;"
            >
              <div class="p-0 m-0">{{item.qtde}}</div>
            </div>
            <button
              class="btn btn-sm t-bolder bg-color-background-alt t-color-text b-0"
              @click.stop="atualizaQuantidadeItem(item, 'inc')"
            >
              &plus;
            </button>
          </div>
        </div>
      </div>
      <div class="flex-item-grow-1">
        <p class="m-0">{{item.nome}}</p>
      </div>
      <div>
        <p class="m-0">{{complemento.gratuito ? 0 : item.preco | currency}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import CircleDot from './svgs/CircleDot'
import CircleEmpty from './svgs/CircleEmpty'
import SquareCheck from './svgs/SquareCheck'
import SquareEmpty from './svgs/SquareEmpty'

export default {
  props: {
    complemento: Object
  },
  components: { CircleDot, CircleEmpty, SquareCheck, SquareEmpty },
  computed: {
    componente () { return this.complemento.componente }
  },
  methods: {
    atualizaQuantidadeItem (item, action) {
      const applied = this.deliveryStore.atualizaQuantidadeItem(this.complemento, item, action)
      // falta sacudir o dialog, mostrar mensagem ou os dois.
      if (!applied) { } //eslint-disable-line
    }
  }
}
</script>

<style scoped>
</style>
