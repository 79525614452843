export default { create }

function create (apiUrl) {
  return {
    hostname: '',
    getDatabase (hostname) {
      this.hostname = hostname
      return new Promise((resolve, reject) => {
        fetch(`${apiUrl}/lojas/${this.hostname}?app=delivery`)
          .then(response => {
            const { status, statusText, ok } = response
            if (!ok || status > 299) {
              throw new Error(statusText || 'Erro comunicando com o servidor')
            } else {
              response.json().then(data => {
                const { database } = data
                resolve(database)
              })
            }
          }).catch((reason) => {
            reject(`não há loja publicada nesse endereço: ${this.hostname} (${reason})`)
          })
      })
    }
  }
}

