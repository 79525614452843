export default function calculaValorDoProduto (produto) {
  let result = produto.preco || 0
  const complementos = produto.complementos || []
  complementos.forEach(({ gratuito, itens }) => {
    if (!gratuito) {
      (itens || []).forEach(({ preco, qtde }) => {
        result += (preco || 0) * (qtde || 0)
      })
    }
  })
  return result * produto.qtde
}
