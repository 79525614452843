import Vue from 'vue'

import deliveryApiFactory from './apiFactory'
import './filters'
import deliveryStoreFactory from './storeFactory'

import App from './App.vue'

const { hostname, port } = window.location
const forceRemoteApi = false
const isProduction = !port || hostname !== 'localhost'
const apiServer = isProduction || forceRemoteApi ? 'https://api.plino.app' : 'https://api-local.plino.app'

Vue.config.productionTip = false
Vue.prototype.api = deliveryApiFactory.create(apiServer)
Vue.prototype.deliveryStore = deliveryStoreFactory.create()

new Vue({
  render: h => h(App),
}).$mount('#app')
