<template>
  <div class="bg-color-background-alt">
    <Complemento
      v-for="(complemento, index) in complementos"
      :key="index"
      :complemento="complemento"
    />
  </div>
</template>

<script>
import Complemento from './Complemento'

export default {
  props: {
    complementos: Array
  },
  components: { Complemento }
}
</script>

<style scoped>
</style>
